/* eslint-disable @typescript-eslint/camelcase */
export default {
  agileDownloadDescription: `Download our guide to learn more about the importance of project management and agile methodologies in organization.`,
  agileDownloadTitle: `Curious about project management or agile approach?`,
  agileImageAlt: `Magazine cover about assets of agile in project management`,
  backToCatalog: `Back to catalogue`,
  callAnAdvidsor: `Call now at 1 877 624.2344`,
  callUs: `Call us at 1 877 624.2344`,
  choose: `Choose`,
  chooseCityAndLanguage: `Choose a location`,
  chooseVirtual: `None of these dates suit you? Select Virtual class`,
  citiesAndFormats: `Locations`,
  closeList: `Close list`,
  confirmed: `Guaranteed to run`,
  courseOutline: `Course outline`,
  downloadTrainingPlan: `Download training plan`,
  downloadingPrepare: `Preparing Download`,
  downloadingTrainingPlan: `Downloading training plan`,
  downloadingConverting: `PDF conversion`,
  date: `Date`,
  date_plural: `Dates`,
  day: `day`,
  day_plural: `days`,
  dayWithCount: `{{count, localizedNumber}} day`,
  dayWithCount_plural: `{{count, localizedNumber}} days`,
  details: `Details`,
  duration: `Duration`,
  durationAverage: `Average duration`,
  faqSchedulesPolicies: `FAQ - Schedules and Policies`,
  fundingPrograms: `Funding programs`,
  inClassroom: `In classroom`,
  inLang: `in`,
  inLoc: `in`,
  inEnglish: `In english`,
  inFrench: `In french`,
  materialIncluded: `Course material included`,
  language: `Language`,
  location: `Location`,
  new: 'New',
  noCoursePlan: `
    <p>This course outline is not available on our web site.</p>
    <p>We invite you to contact our customer service:</p>
    <ul>
      <li><a href="mailto:info@afiexpertise.com" targte="_blank">info@afiexpertise.com</a></li>
      <li>1 877 624.2344</li>
    </ul>
    <p>We look forward to serving you!</p>
  `,
  noScheduledSessionPresentation: `No scheduled session yet. \nThis course is available in private or customized training.`,
  noSessionSuitsNotify: `<strong>No date suits you?</strong>`,
  noSessionForLocationAndLanguage: `No training is available in this language in {{city}}. Please select a new city.`,
  notifyWhenSessionAdded: `Notify me when a session is added.`,
  onRequest: `On request`,
  or: `or`,
  preferentialPrice: `Preferential price`,
  preferentialPriceNote: `We offer preferential prices to public, parapublic, non-profit organizations or those having an agreement with AFI.`,
  preferentialPriceTooltip: `Am I entitled to the preferential price?`,
  privateSession: `Private session`,
  privateSession_plural: `Private sessions`,
  privateSessionAvailable: `Also available in <strong>private or personalized training</strong>.`,
  privateSessionPresentation: `This training is available in a private or personalized format. It can be provided in one of our training centres or at your offices. Call one of our consultants of submit a request online.`,
  promotionalPrice: `Promotional price`,
  recommendedCourses: `Recommended courses`,
  register: `Start registration`,
  registerMobile: `Book my place`,
  regularPrice: `Regular price`,
  requestInformation: `Information request`,
  requestQuotation: `Request a quotation`,
  requestQuote: `Request a quote`,
  scheduledSession: `Scheduled session`,
  scheduledSessionNotification: `Request that a new session be scheduled.`,
  scheduledOrPrivateSession: `Scheduled or private sessions?`,
  selectSessionLocation: `Where to you want to attend it?`,
  select: `Select`,
  selectToShowDates: `Select to show dates and rates`,
  selfPaced: `Self-paced`,
  selfPacedTitle: `Self-Paced Product`,
  selfPacedPresentation: `To learn when you like at your own speed`,
  sessionFull: `full`,
  sessionOnEvening: `evening`,
  sessionOnWeekend: `weekend`,
  sessionRequiredError: `Please check a location and try again.`,
  configurationError: `Configuration error. Missing environment variable. (GATSBY_CART_URL)`,
  showAllSessions: `Show all sessions`,
  showRestOfPlan: `View rest of the training plan`,
  teachersSliderTitle: `Surround yourself with the best`,
  teachingLanguages: `Teaching language`,
  trainingModule: `Training modules`,
  theme: `Theme`,
  upcomingSession: `Upcoming session`,
  upcomingSession_plural: `Upcoming sessions`,
  virtualClassroom: `Virtual class`,
}
