export default {
  homeHeader: `Open the catalogue`,
  title: `Our training offer`,
  intro: `Here is our course offering updated to reflect the ongoing evolution of your needs.`,
  instructions: ``,
  // title: `AFI is committed to supporting you in your virtual work`,
  // intro: `Sign up for one of our guaranteed-to-run 2020 virtual classes by April 30 and receive 40% off.`,
  // instructions: `Once you select the guaranteed-to-run [symbole] virtual class you wish to sign up for, a pop up will appear. In the designated field, please enter <strong>VIRT40</strong> promotional code.`,

  orderBy: {
    byRanking: `Ranked by popularity`,
    byIsNew: `Ranked by newest`,
  },

  byKeywords: `By keyword`,
  nbHits: `{{count}} match`,
  nbHits_plural: `{{count}} matches`,

  noResult: `No result matches your search.`,
  noResultTryEliminateFilter: `Try removing one of the filters above.`,

  refinement: {
    showLess: `Show less`,
    showMore: `Show more`,
    noResult: `No result`,
    searchInCategories: `Find by keyword…`,
    clearThemes: `Remove filters Topics`,
    clearEditors: `Remove filters Brands`,
    addFilter: `Refine the results of scheduled sessions`,
    addFilterCompact: `Refine from scheduled sessions`,
    otherCategories: `Categories`,

    items: {
      sessions: `Scheduled sessions:`,
      confirmed: `with Guaranteed to Run sessions`,
      SessionsConfirmed: `with Guaranteed to Run sessions`,
      virtual: `Virtual Classroom`,
      // eslint-disable-next-line prettier/prettier
      Québec: `in Québec`,
      // eslint-disable-next-line prettier/prettier
      Montréal: `in Montréal`,
      Laval: `in Laval`,
      fr: `in french`,
      en: `in english`,
    },

    byTheme: `Topics`,
    byEditors: `Brands`,
    byAccreditations: `Professional certifications`,
    byCity: `Offered at`,
    byLanguage: `Delivered in`,
    filtersInstructions: `Select multiple filters if necessary. Maximum of one choice per group.`,
  },

  downloadCatalog: {
    title: `Download the<br />2023 Catalogue`,
    yourEmail: `Your Email Here`,
  },

  features: {
    more: `Learn more`,
    private: {
      title: `Private training`,
      text: `Do you need to align your teams’ skills with your business vision? We can help.`,
    },
    virtual: {
      title: `Virtual classroom`,
      text: `No matter where you are, we offer you the option of taking our online training courses via our virtual classrooms.`,
    },
    promo: {
      title: `Packages and promotions`,
      text: `Let us advise you on the various options that are available to your business in order to maximize your training budget and get a return on investment.`,
    },
  },

  magazine: {
    title: `Explore Magazine`,
    heading: `Download Explore Magazine`,
    cta: `Download your copy`,
    description: `Download Explore 2023 and save 20% on your next course.`,
    terms: `*<a href="https://www.afiexpertise.com/en/a-propos/faq" target="_blank">Terms and conditions</a> apply.`,
    summaryHeading: `In this issue`,
    summary: `
<li>Get to know 13 impactful humans and their courses recommendations</li>
<li>Accelerate your leadership skills</li>
<li>
Explore 10 IT certifications in demand for 2023
</li>
<li>Learn from Edgenda experts’ interviews

<ul class="list-disc pl-4 space-y-2">
<li>Nathalie Gosselin, Chief Executive Strategy and Consulting Services, Edgenda</li>
<li>Julie Castonguay, Chief Experience Officer, Afi by Edgenda</li>
<li>Pierre-Alexandre Bradette, Chief Operating Officer, Apprentx by Edgenda</li>
</ul>

</li>
<li>Browse our course offering and be ready for fall.</li>`,
  },
  coloredTitle: {
    firstPart: 'Trainings',
    firstPartIn: 'Trainings in',
    secondPart: 'Training Courses',
  },
  human: 'Human capital',
  offerParagraph:
    'Browse through our regularly updated course catalog to meet your ever-evolving skill development needs.',
  seeOnlyNewItems: `see only new courses`,
  news: `New courses`,
}
